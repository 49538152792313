// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',

  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/luckydrawuat',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
LICENSEKEY:"ARUjhi5OCAB7N/tpygg+hhsd22dFMbSS5Gxju5lQv1EudZkbAji+691ROBb+HGY10jCVAEEVWs2cYHYkcxDXW+FOJe9JaWO72TVydelcVwVIbhHEtm6hASg0j7LENNswkUgLGT1GJOg6fBfZ1whQTIhL8EqFJRrM13EBE8NAytYKcyTmRkQWAe84z5NMAP/KmChYf5oI6ZHrBI5cZCFErLVEN2jDC85qt6/EmXufxT1YnieF0tKs1XR/O+LIkjb7d4ABVxpSCoeATzoP6UxPGDdPUmmyxAoo7gSIfkiIaqgcExTR57AkN6tqbRFOXUe7O2dCueztufhPz9q9oRbA+o4veT4UKeEsQWiyUMsnrC7CspwCJlkX8v+rm7jAmsueG9amNh/vlvldrU+xUsfxue+WXLwHxmfYfCiJ7f2UkyZE3oCmhJQ1ka7xkePWCNOaJK3kCfd/IRukPHyzuxvkv7snYRoJiQFrlj0Ugy6q0hy3npe5JM1mRWX6E8OCskjqb1ZS4uHt4Cg/Witu4TrkLv29hMtcxIkPoIo57e6qcV5su0hxeVTTUFC5F49755LuysgjpNBuFGsSrkY0RQmVQoXS4ebtzOSqnUgKfoQEXe3mAl2+7v36m1HnNdViKmMmnWAHhVM4YQ+cplb71sT5uY4vd9ZlOKmERbuB4q4IUNah7aeKslJUGrWovl6IYzo7l6daTKRCsOWOHZZWTvVBqjOFkOINF4hWPvshhPSOphDiL9kufOHlGyF9BQD5gpVQSVqZ5OgPcD8C3eriIlZneP6PP2u+q6Pw7Qp7IpP+DgvbfUTPO6GVxp38jWRhK8B6dQJGb5rFtwwd/9LOXQE+YTET51imdSkWe9Q2lUW/SK2Uu73V",
  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  //OAUTH_TOKEN:'https://cap-api-eu.sttarter.com/v3',
  OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',
  ACCOUNT_PASSWORD:'Test@123',
  ACCOUNT_CONFIRM_PASSWORD:'Test@123',
  PASSWORD:'test@123',
  CONFIRM_PASSWORD:'test@123',

  BRAND: 'ABBOTTSGDEMO',
  DEVICE_ID: '1234551231',
  //DEVICE_ID: '12345',
  IDENTIFIER_TYPE: 'MOBILE',
  //TILL_ID: 'abbott.sg.demo.admin',
  TILL_ID: 'abbottsgdemo',
  COUNTRY_CODE: 'SG',
  LANGUAGE: 'en_US',
  MAXMOBNUM: 10,
  MINMOBNUM: 8,
  rewarddetail: 'detail',
  ORG_ID: '2021',
  bugsnag_version: '10112023',
  STTARTER_INSTRUCTIONS:'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/rewardsinstruction',
  STTARTER_TERMSANDCONDITION: 'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/t&c',
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"
};

// https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/skupoints

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
